<template>
	<el-dialog class="dialog" title="查看记录" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px">
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column prop="name" label="设备名称">
					</el-table-column>
					<el-table-column prop="type" label="检修类型">
					</el-table-column>
					<el-table-column prop="stat" label="含水量(%)">
					</el-table-column>
					<el-table-column prop="PHI" label="颗粒度(PHI)">
					</el-table-column>
				</el-table>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: "",
				loading: false,
				formData: {
				},
				tableData: [{
					name: '滚动轴承(型号)',
				}, {
					name: '轴(型号)',
				}, {
					name: '轴(型号)',
				}, {
					name: '滑动轴承(型号)',
				}, {
					name: '吐丝头(型号)',
				}, {
					name: '润滑油检测状态',
				}, {
					name: '联轴器对中状态',
				}, {
					name: '地脚螺栓紧固状态',
				}],
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {
					type: 1
				};
				this.get();
			},
			//获取数据
			get() {

			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.el-dialog {
			min-width: 900px;

		}

		::v-deep.cascader {
			display: block;
		}

		::v-deep.el-image {
			width: 80px;
			height: 80px;
			margin-right: 20px;
		}

		.images {
			margin: 10px 25px;
		}
	}

	.avatar-uploader {
		display: inline-block;
		margin-right: 20px;

		::v-deep .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			width: 80px;
			height: 80px;
		}

		::v-deep .el-upload:hover {
			border-color: #409EFF;
		}
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}
</style>
