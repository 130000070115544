<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">

					<el-col :span="8">
						<el-form-item label="厂区">
							<el-select v-model="searchParm.area" collapse-tags clearable
								:popper-append-to-body="false" placeholder="请选择" @change="$forceUpdate()">
								<el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="8">
						<el-form-item label="车间">
							<el-select v-model="searchParm.chejian" collapse-tags clearable
								:popper-append-to-body="false" placeholder="请选择" @change="$forceUpdate()">
								<el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="8">
						<el-form-item label="设备名称">
							<el-select v-model="searchParm.name" collapse-tags clearable
								:popper-append-to-body="false" placeholder="请选择" @change="$forceUpdate()">
								<el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">
						新增记录
					</el-button>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
							style="margin-bottom: 20px;">
							<el-table-column prop="name" align="center" label="设备名称" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="type" align="center" label="检修类型" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="time" align="center" label="录入时间" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="water" align="center" label="含水量(%)" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="phi" align="center" label="颗粒度(PHI)" show-overflow-tooltip>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="160">
								<template slot-scope="scope">
									<el-button type="text" @click="look(scope.row)">
										查看
									</el-button>
									<el-button type="text" @click="edit(scope.row)">
										编辑
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>

		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
		<look :isShow.sync="showLookDialog" :dialogObj="lookObj"></look>
	</div>
</template>
<script>
	import smCard from "@c/smCard.vue"
	import edit from "./edit.vue"
	import look from "./look.vue"
	export default {
		components: {
			smCard,
			edit,
			look
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: [],
				},
				searchParm: {
					size: 10,
					current: 1
				},
				// batchActionType: '',
				showEditDialog: false,
				showLookDialog: false,
				editObj: {},
				lookObj: {},
				roles: [],
			}
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect()
					this.tableHeight = rect.height
				})
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 10,
					current: 1
				}
			},
			//获取账户角色
			getRoles() {
				this.$get("/user-api/plat/role/list", null).then((res) => {
					if (res.code == 1000) {
						this.roles = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//展示数据
			list() {
				this.$get("/user-api/ent/user/child/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开查看弹窗
			look(row) {
				this.lookObj = {};
				this.lookObj["id"] = row.id;
				this.showLookDialog = true;
			},
			//打开新增弹窗
			insert() {
				this.editObj = {};
				this.editObj["type"] = 2;
				this.showEditDialog = true;
			},
			//打开编辑弹窗
			edit(row) {
				this.editObj = {};
				this.editObj["id"] = row.id;
				this.editObj["type"] = 1;
				this.showEditDialog = true;
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			//点击账户启用状态
			changeSwitch(val, row) {
				this.$post("/user-api/ent/user/status/update", {
					'id': row.id,
					'status': val
				}).then((res) => {
					if (res.code == 1000) {
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//删除
			remove(row) {
				this.$confirm("是否确认删除？", "删除子账号", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post("/user-api/ent/user/delete", {
						id: row.id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
		},
		mounted() {
			this.getRoles();
			this.calHeight();
			window.addEventListener('resize', this.calHeight)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight)
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss"
</style>
